const colors = {
  Cyan: {
    1: "#e9fcfb",
    2: "#c8f8f6",
    3: "#94f2ed",
    4: "#5cece4",
    5: "#23e5db",
    6: "#16c7bf",
    7: "#00a49f",
    8: "#2c7b78",
    9: "#2e6360",
    10: "#2b514e"
  },
  Grey: {
    1: "#E3E4E7",
    2: "#C7CACE",
    3: "#ACAFB6",
    4: "#90949E",
    5: "#747A85",
    6: "#585F6D",
    7: "#3D4455",
    8: "#212A3C",
    9: "#050F24",
    10: "#474951",
    11: "#32343a",
    12: "#1e1f23",
    13: "#B4B7BD",
    14: "#F2F3F4"
  },
  BlueGrey: {
    1: "#f1f2f5",
    2: "#d5d8e0",
    3: "#c7ccd6",
    4: "#abb2c1",
    5: "#8490a8",
    6: "#717e9d",
    7: "#596685",
    8: "#3a4f81",
    9: "#223b77",
    10: "#102a67",
    11: "#09225c",
    12: "#001443"
  },
  Blue: {
    1: "#edf3fe",
    2: "#c9dbfb",
    3: "#93b6f8",
    4: "#5d92f5",
    5: "#276ef1",
    6: "#0f5cf3",
    7: "#074ee5",
    8: "#0343d3",
    9: "#0038c0",
    10: "#002a90",
    11: "#001c60",
    12: "#000e30",
    13: "#1B2D93"
  },
  Red: {
    1: "#FFE8F1",
    2: "#FFE8F1",
    3: "#FFE8F1",
    4: "#FFA5CB",
    5: "#F375AA",
    6: "#FB5247",
    7: "#FF706F",
    8: "#BA346C",
    9: "#711E41",
    // Kept same as perv red color due to missing 10,11,12
    10: "#771f1f",
    11: "#4f1515",
    12: "#280a0a",
    13: "#FFF5F5" //OTP ERROR BG
  },
  Green: {
    1: "#EFFFCD",
    2: "#9ee8d2",
    3: "#CBE98A",
    4: "#4cd0a9",
    5: "#ABD354",
    6: "#94C52B",
    7: "#00BA88",
    8: "#7CAA17",
    9: "#436A11",
    10: "#136049",
    11: "#1b4438",
    12: "#182924",
    13: "#00BA88"
  },
  LightGreen: {
    1: "#EDF1DC"
  },
  // Yellow: {
  //   1: "#fff6e8",
  //   2: "#ffe4b9",
  //   3: "#ffd38b",
  //   4: "#ffc15c",
  //   5: "#ffaf2e",
  //   6: "#e69e2b",
  //   7: "#cc8d27",
  //   8: "#ad7822",
  //   9: "#97681d",
  //   10: "#805819",
  //   11: "#554428",
  //   12: "#2b261f"
  // },

  Yellow: {
    1: "#FFF8BB",
    2: "#FFDB66",
    3: "#ffd38b",
    4: "#ffc15c",
    5: "#FFC401",
    6: "#EFA920",
    7: "#cc8d27",
    8: "#B98219",
    9: "#80570B",
    10: "#805819",
    11: "#554428",
    12: "#2b261f"
  },

  LightBlue: {
    1: "#f2f9ff",
    2: "#d8edff",
    3: "#b0dcff",
    4: "#89caff",
    5: "#61b8ff",
    6: "#4eabf7",
    7: "#3496e5",
    8: "#2a83cb",
    9: "#236da9",
    10: "#1c5787",
    11: "#154165",
    12: "#0b2133"
  },
  Purple: {
    1: "#1B2D93",
    2: "#7353dd",
    3: "#EEEEF6"
  },
  Orange: {
    1: "#FFF3E8",
    2: "#FFE6C8",
    3: "#FFC37D",
    4: "#FF8718",
    5: "#FF9518",
    6: "#D36703",
    7: "#D36703",
    8: "#9D4D03",
    9: "#683B10"
  },
  White: {
    1: "#ffffff"
  }
};

export default colors;
